import { InMemoryCacheConfig } from '@apollo/client';

/*
    Configuration of the InMemoryCache that will be used with the Apollo Client.
 */

export const cacheConfig: InMemoryCacheConfig = {
	typePolicies: {
		Station: {
			keyFields: ["id"],
		},
		SingleDaily: {
			keyFields: ["id", "date", "variable"],
		},
		RangedDaily: {
			keyFields: ["id", "dates", "variable"],
		},
	},
};
