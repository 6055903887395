import { client } from 'apollo';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { DEV_MODE } from 'util/constants';

import { ApolloProvider } from '@apollo/client';

import App from './App';
import appSetup from './setup';

appSetup(DEV_MODE);

ReactDOM.render(
	<ApolloProvider client={client}>
		<RecoilRoot>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</RecoilRoot>
	</ApolloProvider>,
	document.getElementById("root")
);
