import React, { Suspense } from "react";
import "./styles/app.sass";
import { Router, Switch, Route } from "react-router-dom";
import history from "routes/history";
import routes from "routes";

const App = () => (
  <Router history={history}>
    <Suspense fallback={null}>
      <Switch>
        {routes.map(({ path, render, exact }, i) => (
          <Route exact={exact} path={path} render={() => render} key={i} />
        ))}
      </Switch>
    </Suspense>
  </Router>
);

export default App;
